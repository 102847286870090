import { randomNumber } from "../utils/functions/numbers";
import { ReactNode, useEffect, useRef } from "react";

interface ISliderProps {
    interval: number;
    children: ReactNode | ReactNode[];
    randomizeTime?: boolean | number;
    className?: string;
}

/* interface IPlayVideo {
    slideChildren: Element[];
    isIntersection: boolean;
    indextarget?: number;
} */

interface IObserverSlide {
    target: HTMLDivElement;
    interval: number;
    lengthSlides: number;
}

interface IGetInterval {
    interval: number;
    randomizeTime: boolean | number;
}

interface IChangeSlide {
    target: Element;
    widthElement: number;
    direction: number;
    lengthSlides: number;
    slideChildren: Element[];
}

/* function playVideo({ slideChildren, isIntersection, indextarget }: IPlayVideo) {
    if (slideChildren.length <= 0) return;

    const isArray = Array.isArray(slideChildren);
    if (!isArray || typeof indextarget === "undefined") return;

    const videoElement = slideChildren[indextarget] as HTMLVideoElement;
    if (!videoElement || videoElement.tagName !== "VIDEO") return;

    if (!isIntersection) videoElement.pause();
    else videoElement.play();
} */

function getInterval({ interval, randomizeTime }: IGetInterval): number {
    if (!randomizeTime) return 7000;
    if (!Number.isNaN(randomNumber)) Number(randomizeTime);

    const type = typeof randomizeTime;
    switch (type) {
        case "string":
            randomizeTime = randomNumber(0, randomizeTime as number) as number;
            break;
        case "boolean":
            randomizeTime = randomNumber(0, 1000) as number;
            break;
        default:
            randomizeTime = 0;
            break;
    }
    return interval + randomizeTime;
}

function changeSlide({ target, widthElement, direction = 1, lengthSlides }: IChangeSlide): void {
    let currentslide = target?.getAttribute("data-active-slide") || 0;
    currentslide = typeof currentslide === "string" ? Number(currentslide) : currentslide;
    let nextslide = currentslide + direction >= lengthSlides ? 0 : currentslide + direction;

    target.setAttribute("data-active-slide", String(nextslide));
    target.scrollTo({
        left: nextslide * widthElement,
        top: 0,
        behavior: "smooth",
    });
}

function observeSlide({ target, interval, lengthSlides }: IObserverSlide): void {
    let timer: NodeJS.Timer | undefined;
    let widthElement = target.offsetWidth;

    const callback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
            const slideChildren = Array.from(entry.target.children);
            if (entry.isIntersecting) {
                if (lengthSlides > 1) {
                    timer = setInterval(() => {
                        changeSlide({
                            target: entry.target,
                            widthElement,
                            direction: 1,
                            lengthSlides,
                            slideChildren,
                        });
                    }, interval);
                }
            } else clearInterval(timer);
        });
    };

    const options: IntersectionObserverInit = {
        root: null,
        rootMargin: "5px",
        threshold: 0.5,
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(target);
    target.setAttribute("data-observe-slide", "true");
}

export default function Slider({ interval, children, className, randomizeTime }: ISliderProps) {
    const ref = useRef<HTMLDivElement>(null);

    const isArray = Array.isArray(children);
    interval = randomizeTime ? getInterval({ interval, randomizeTime }) : 7000;
    const lengthSlides = isArray ? children.length : 1;

    useEffect(() => {
        if (ref.current) {
            const target = ref.current;
            const isObserve = target.getAttribute("data-observe-slide");
            !isObserve && observeSlide({ target, interval, lengthSlides });
        }
    }, [ref.current]);

    return (
        <div
            ref={ref}
            data-active-slide="0"
            className={`${className ? className : ""} slider_media_clubok_24`}
        >
            {children}
        </div>
    );
}
