import React, { useEffect, useRef, useCallback } from "react";
import { throttle } from "../utils/functions/throttlers";

interface IInfiniteFeed {
    children: React.ReactNode;
    offsetBottom: number;
    onEndScroll: () => void;

    throttle_ms?: number;
    className?: string;
}

export default function InfiniteFeed({
    className,
    children,
    throttle_ms = 200,
    offsetBottom,
    onEndScroll,
}: IInfiniteFeed) {
    const appRef = useRef<HTMLDivElement>(null);

    const scrollHandler = useCallback((): void => {
        if (!appRef || !appRef.current) return;
        const offsetHeightApp: number = appRef.current.offsetHeight;
        if (window.scrollY + window.innerHeight >= offsetHeightApp - offsetBottom) onEndScroll();
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", throttle(scrollHandler, throttle_ms));
        return () => window.removeEventListener("scroll", scrollHandler);
    }, [scrollHandler]);

    return (
        <div ref={appRef} className={`${className} infinite_feed`}>
            {children}
        </div>
    );
}
