import { useEffect } from "react";

const getOffsetTop = function (element) {
    if (!element) return 0;
    return getOffsetTop(element?.offsetParent) + element?.offsetTop;
};

function getCookie(name) {
    let matches = document.cookie.match(
        new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {
    options = {
        path: "/",
        ...options,
    };

    if (options.expires instanceof Date) options.expires = options.expires.toUTCString();

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) updatedCookie += "=" + optionValue;
    }

    document.cookie = updatedCookie;
}

function generateRandomName(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

function useVisitorData() {
    const visitorId = getCookie("visitorId");
    if (visitorId === undefined)
        setCookie("visitorId", generateRandomName(10), { secure: true, "max-age": 7775999 });

    const data = { visitorId };
    return { data };
}

const ReactScroll = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [window.location.pathname]);
    return <></>;
};
export default ReactScroll;
export { ReactScroll, useVisitorData };
