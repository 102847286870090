import { IMiniAdv, ITrashItem } from "../models";
import React, { useState, useEffect } from "react";
import { RecordsService } from "../services/records.service";
import { AdvService } from "../services/adv.service";
import { useResize } from "../hooks/useResize";

type FeedProviderProps = { children: React.ReactNode };
type MethodState = "increment" | "replace";
type NameState = "adsItemsRight" | "adsItemsLeft" | "trashItems";
type ContentState = IMiniAdv[] | ITrashItem[];

type RequestState = {
    limit: number;
    method: MethodState;
    name: NameState;
};

interface ISetContentState {
    name: NameState;
    content: ContentState;
    method: MethodState;
}

interface IFeedContext {
    adsItemsLeft: IMiniAdv[];
    adsItemsRight: IMiniAdv[];
    trashItems: ITrashItem[];
    getTrash: ({}: RequestState) => void;
    getAds: ({}: RequestState) => void;
    setContentState: ({}: ISetContentState) => void;
}

const FeedStateContext = React.createContext<IFeedContext | null>(null);
const FeedDispatchContext = React.createContext<IFeedContext | null>(null);

function FeedProvider({ children }: FeedProviderProps) {
    const { width = 781 } = useResize();
    const mobileSize = width <= 780;

    const setContentState = ({ name, content, method }: ISetContentState) => {
        switch (method) {
            case "increment":
                if (!Array.isArray(content) && !Array.isArray(state[name])) return;

                setState((prevstate) => ({
                    ...prevstate,
                    [name]: [...prevstate[name], ...content],
                }));
                break;
            case "replace":
            default:
                setState((prevstate) => ({ ...prevstate, [name]: content }));
                break;
        }
    };
    const getTrash = async ({ limit, method, name }: RequestState) => {
        await RecordsService.getRecords({
            params: { type: "get", limit },
            callback: (content: ITrashItem[]) => setContentState({ name, content, method }),
        });
    };
    const getAds = async ({ limit, method, name }: RequestState) => {
        await AdvService.getByLimit({
            limit,
            callback: (content: IMiniAdv[]) => setContentState({ name, content, method }),
        });
    };
    const getAllData = async () => {
        setFetching(true);
        await getTrash({ limit: mobileSize ? 5 : 9, method: "replace", name: "trashItems" });
        await getAds({ limit: 4, method: "replace", name: "adsItemsLeft" });
        await getAds({ limit: 4, method: "replace", name: "adsItemsRight" });
    };

    const defaultState = {
        adsItemsLeft: [],
        adsItemsRight: [],
        trashItems: [],
        getTrash,
        getAds,
        setContentState,
    };

    const [state, setState] = useState<IFeedContext>(defaultState);
    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        if (!fetching) getAllData();
    }, []);

    return (
        <FeedStateContext.Provider value={state}>
            <FeedDispatchContext.Provider value={state}>{children}</FeedDispatchContext.Provider>
        </FeedStateContext.Provider>
    );
}

function useFeedState() {
    const context = React.useContext(FeedStateContext);
    if (context === undefined) {
        throw new Error("useCountState must be used within a CountProvider");
    }
    return context;
}

function useFeedDispatch() {
    const context = React.useContext(FeedDispatchContext);
    if (context === undefined) {
        throw new Error("useCountDispatch must be used within a CountProvider");
    }
    return context;
}

export { FeedProvider, useFeedDispatch, useFeedState };
