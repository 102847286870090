import axios from "axios";

const url_admin = `https://club.clubok24.ru/`;
const url_api = `${url_admin}api/`;
const url_rating = `${url_api}rating.php`;

const siteName = "super-story.ru";

const url = `https://${siteName}/`;
const url_scripts = `${url}scripts/`;
const url_scripts_trash = `${url_scripts}news.php`;
const url_scripts_counters = `${url_scripts}counters.php`;
const url_record = `${url_scripts}record.php`;

interface IParamsRecords {
    type: string;
    id?: string;
    limit?: number;
}
interface IRecords {
    params: IParamsRecords;
    callback?: Function;
    callbackFinally?: Function;
}

interface IParamsRecord {
    format: string;
    limit: number;
    link?: string;
}
interface IRecord {
    params: IParamsRecord;
    callback: Function;
}

interface IParamsViews {
    id: string;
    t: string;
}
interface IViews {
    params: IParamsViews;
    callbackFinally?: Function;
}

interface IParamsRating {
    user_id: string;
    news_id: string | number;
    score: string | number;
}
interface ISetRating {
    params: IParamsRating;
    callback?: Function;
}

/* interface IGetRating {
    news_id: string | number;
    callback?: Function;
} */

export const RecordsService = {
    async getRecords({ params, callback, callbackFinally }: IRecords) {
        const { type, id, limit } = params;
        const p_id = id ? `&id=${id}` : "";
        const p_limit = limit ? `&limit=${limit}` : "";
        axios
            .get(`${url_scripts_trash}?t=${type}${p_id}${p_limit}`)
            .then(({ data }) => callback && callback(Object.values(data)))
            .finally(() => callbackFinally && callbackFinally());
    },
    async getByLink({ params, callback }: IRecord) {
        const { format, link, limit = 6 } = params;
        axios
            .get(`${url_record}?format=${format}&link=${link}&limit=${limit}`)
            .then((response) => callback(response));
    },
    async setView({ params, callbackFinally }: IViews) {
        const { id, t } = params;
        axios
            .get(`${url_scripts_counters}?t=${t}&id=${id}`)
            .finally(() => callbackFinally && callbackFinally());
    },
    async setRating({ params, callback }: ISetRating) {
        if (typeof params !== "object") return;
        const { news_id, score, user_id } = params;
        axios
            .get(
                `${url_rating}?callback=average&site=${siteName}&news_id=${news_id}&score=${score}&user_id=${user_id}`
            )
            .then(({ data }) => callback && callback(data));
    },
    /* async getRating({ news_id, callback }: IGetRating) {
        if (typeof news_id !== "string") return;
        axios
            .get(`${url_rating}?site=${siteName}&news_id=${news_id}`)
            .then(({ data }) => callback && callback(Object.values(data)));
    }, */
};
