import { RecordsService } from "../../services/records.service";

export default function onClickLink(e: React.MouseEvent<HTMLAnchorElement>): void {
    const onFinnaly = (href: string) => window.open(href, "_blank");
    e.preventDefault();
    e.stopPropagation();
    const id = e.currentTarget.dataset.id || "";
    const href = e.currentTarget.href;
    RecordsService.setView({ params: { id, t: "adv" }, callbackFinally: () => onFinnaly(href) });
}
