import React, { ChangeEvent } from "react";
import Cookies from "universal-cookie";
import { useVisitorData } from "../../utils/functions/ReactHooks";
import { RecordsService } from "../../services/records.service";
import { IContentItem } from "../../models";
import { FC } from "react";

interface IFull_RatingProps {
    id: string | number;
    score: string | number;
    popularity: string | number;
    setRecord: Function;
}

const FULL_RATING: FC<IFull_RatingProps> = ({ id, score, popularity, setRecord }: IFull_RatingProps) => {
    const cookies = new Cookies();
    const { data } = useVisitorData();

    const onChangeRating = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        const dataid = e.target.dataset.id;

        console.log(id, dataid);

        if (isNaN(Number(val)) || !dataid) return false;
        const visitor_id = data?.visitorId;
        cookies.set(`ratingClubok24_post${dataid}`, val, { maxAge: 7776000 });

        function callback(data: IContentItem) {
            const newScore = data.score;
            const newPopularity = data.popularity;
            setRecord((prevstate: IContentItem) => ({
                ...prevstate,
                score: newScore,
                popularity: newPopularity,
            }));
        }

        RecordsService.setRating({
            params: {
                news_id: dataid,
                score: val,
                user_id: visitor_id || "new_user",
            },
            callback,
        });
    };

    function stars(length: number) {
        const starsArray = [];
        for (let i = length; i > 0; i--) {
            starsArray.push(
                <React.Fragment key={`${i}_${id}`}>
                    <input
                        data-id={id}
                        onChange={onChangeRating}
                        className="star_rating_input"
                        id={`star-rating${i}_${id}`}
                        type="radio"
                        name="rating"
                        value={i}
                    />
                    <label
                        className={`star_rating_label ${
                            cookies.get(`ratingClubok24_post${id}`) >= i
                                ? "star_rating_checked"
                                : Number(score) >= i
                                ? "star_rating_checked"
                                : ""
                        }  fa fa-star-o fa-lg`}
                        htmlFor={`star-rating${i}_${id}`}
                        title={`Оценка ${i}`}
                    />
                </React.Fragment>
            );
        }
        return starsArray;
    }

    return (
        <div className="rating_wrapper">
            <div className="rating_title">Оцените эту статью:</div>
            <div className="rating_stars_numbers">
                <div className="rating_stars_box">{stars(5)}</div>
                <div className="rating_numbers">
                    {score} <span className="rating_popularity">{popularity}</span>
                </div>
            </div>
        </div>
    );
};

export default FULL_RATING;
