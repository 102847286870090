import axios from "axios";

const url = "https://super-story.ru/";
const url_scripts = `${url}scripts/`;
const url_scripts_trash = `${url_scripts}moneys.php`;

interface IAdv {
    limit: number;
    callback?: Function;
    callbackFinally?: Function;
}

export const AdvService = {
    async getByLimit({ limit, callback, callbackFinally }: IAdv) {
        axios
            .get(`${url_scripts_trash}?limit=${limit}`)
            .then(({ data }) => {
                const dataArray = Object.values(data);
                callback && callback(dataArray);
            })
            .finally(() => callbackFinally && callbackFinally());
    },
};
