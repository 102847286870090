import { BrowserRouter, Route, Routes } from "react-router-dom";
import Wrapper from "./wrappers/Wrapper";
import "./App.css";
import "./ADV.css";
import AngryPopup from "./components/notifications/AngryPopup";
import { FeedProvider } from "./context/FeedProvider";
import Observers from "./handlers/Observers";

export default function App() {
    return (
        <FeedProvider>
            <AngryPopup timeout={10000} />
            <Observers>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Wrapper />}>
                            <Route path=":outerlink" element={<Wrapper />}>
                                <Route path=":innerlink" element={<Wrapper />} />
                            </Route>
                        </Route>
                        <Route path="*" element={<Wrapper />} />
                    </Routes>
                </BrowserRouter>
            </Observers>
        </FeedProvider>
    );
}
