import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IContentItem, ITrashItem } from "../../models";
import Short from "./Short";
import Full from "./Full";
import TrashRecord from "./TrashRecord";
import React from "react";
import { randomNumber } from "../../utils/functions/numbers";
import { RecordsService } from "../../services/records.service";

interface IResponseRecord {
    data: { record: IContentItem; trashitems: ITrashItem };
}
interface IUnusualRecordProps {
    random?: boolean;
}
const limitTrashPlaceholder = 4,
    randGrow = randomNumber(1, 2);

export default function UnusualRecord({ random = false }: IUnusualRecordProps) {
    const [record, setRecord] = useState<IContentItem>();
    const [trashPlaceholder, setTrashPlaceHolder] = useState<ITrashItem[]>([]);

    const { outerlink, innerlink } = useParams();
    const typeRecord: string = innerlink ? "full" : "short";
    let countTrashItems = 0;

    const onResponse = ({ data }: IResponseRecord): void => {
        const { record, trashitems } = data;
        setRecord(record);
        setTrashPlaceHolder(Object.values(trashitems));
    };

    useEffect(() => {
        const params = {
            link: random ? "random" : outerlink || "",
            format: typeRecord,
            limit: 6,
        };
        (outerlink || innerlink) && RecordsService.getByLink({ params, callback: onResponse });
    }, [outerlink, innerlink]);

    if (!record) return <></>;

    const topTrashRecords = trashPlaceholder.map((element, index) => {
        if (index < limitTrashPlaceholder || countTrashItems >= 3)
            return <React.Fragment key={index}></React.Fragment>;
        countTrashItems++;
        return <TrashRecord trash={element} key={index} />;
    });
    const short_content = (
        <>
            <Short record={record} />
            <TrashRecord trash={trashPlaceholder[0]} />
        </>
    );
    const full_content = (
        <>
            <Full record={record} setRecord={setRecord} />
            <div className="col_wrapper">
                <div className="sticky">
                    {trashPlaceholder.map(
                        (element, index) =>
                            index < limitTrashPlaceholder && <TrashRecord trash={element} key={element.id} />
                    )}
                </div>
            </div>
        </>
    );

    return (
        <>
            <div data-grow_child={randGrow} data-count_child={2} className={`row`}>
                {topTrashRecords}
            </div>
            <div data-grow_child={1} data-count_child={2} className={`row ${typeRecord}`}>
                {typeRecord === "short" ? short_content : full_content}
            </div>
        </>
    );
}
