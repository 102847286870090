import React from "react";
import { ITrashItem } from "../../models";
import { RecordsService } from "../../services/records.service";
import getMedia from "../../utils/functions/getMedia";

interface TrashItemProps {
    trash?: ITrashItem;
}

const url = "https://club.clubok24.ru/content/";

const onClickTrash = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    const onFinnaly = (href: string) => window.open(href, "_blank");
    e.preventDefault();
    e.stopPropagation();
    const id = e.currentTarget.dataset.id || "";
    const href = e.currentTarget.href;
    RecordsService.setView({ params: { id, t: "adv" }, callbackFinally: () => onFinnaly(href) });
};

export default function TrashRecord({ trash }: TrashItemProps) {
    const { id, title, footnote, link, img, img_web, rgba, contrast, path } = trash || {};
    const styleVariable = {
        "--rgb_color_aplha": `${rgba}, 0)`,
        "--rgb_color": `${rgba}, 1)`,
    } as React.CSSProperties;

    const prefix = "https://club.clubok24.ru/content/";
    const src_array: string[] = img?.split(",") || [];
    const mediaElements: React.JSX.Element[] = getMedia({ src_array, prefix });
    return (
        <a
            data-id={id}
            data-path={path}
            className="item_cell unusual"
            style={{ backgroundColor: `${rgba})` }}
            href={link}
            tabIndex={0}
            target="_blank"
            onClick={onClickTrash}
            onMouseDown={onClickTrash}
        >
            <span className="mediaElement">
                {mediaElements[0]}
                <span style={styleVariable} className="gradient" />
            </span>
            <span
                style={{
                    backgroundColor: `${rgba})`,
                    color: contrast,
                }}
                className="contentElement"
            >
                <span className="title">{title}</span>
                <span className="footnote">{footnote}</span>
            </span>
        </a>
    );
}
