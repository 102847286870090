import React, { useState, useEffect } from "react";
import { ITrashItem } from "../../models";
import TrashRecord from "../items/TrashRecord";
import { RecordsService } from "../../services/records.service";

interface IAngryPopup {
    timeout: number;
}

export default function AngryPopup({ timeout }: IAngryPopup) {
    const [trashItems, setTrashItems] = useState<ITrashItem[]>([]);
    const [visible, setVisible] = useState<boolean>(false);

    const onClickClose = (): void => {
        setVisible(false);
        setTrashItems([]);
    };
    const onResponse = (data: ITrashItem[]): void => setTrashItems(Object.values(data));
    const onFinally = (): void => setVisible(true);

    useEffect(() => {
        let seconds: number = 0;
        const timer = setInterval(() => {
            if (seconds * 1000 < timeout) seconds++;
            else {
                RecordsService.getRecords({
                    params: { type: "get", limit: 6 },
                    callback: onResponse,
                    callbackFinally: onFinally,
                });
                clearTimeout(timer);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    if (!visible) return <div style={{ display: "none" }}></div>;
    return (
        <div className="angry_popup">
            <div className="backdrop" />
            <div className="angry_wrapper">
                <div className="head">
                    <span className="title">Главные новости</span>
                    <span onClick={onClickClose} className="close">
                        <span className="top"></span>
                        <span className="bot"></span>
                    </span>
                </div>
                <div className="content">
                    {trashItems.map((trash, index) => {
                        return (
                            <React.Fragment key={index}>
                                <TrashRecord trash={trash} />
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
