import React from "react";

type Props = {
    onClick?: () => void;
};

const Loading = ({ onClick }: Props) => {
    return <div title="Загрузка.." onClick={onClick} className="loader" />;
};

export default Loading;
