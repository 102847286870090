import { IContentItem } from "../../models";
import React, { useRef, useEffect, useMemo } from "react";
import { useFeedState } from "../../context/FeedProvider";
import FULL_SHARE from "./FULL_SHARE";
import ADV_MINI from "./ADV_MINI";
import FULL_RATING from "./FULL_RATING";
import FULL_OKLIKE from "./FULL_OKLIKE";
import getMedia from "../../utils/functions/getMedia";

interface RecordProps {
    record: IContentItem | undefined;
    setRecord: Function;
}
const url = "https://club.clubok24.ru/content/";

function Full({ record, setRecord }: RecordProps) {
    const { adsItemsLeft = [] } = useFeedState() || {};
    const {
        id = "1",
        title = "",
        description = "",
        img = "",
        img_web = "",
        link = "",
        view = "",
        date_local = "",
        time_local = "",
        rgba = "",
        contrast = "",
        popularity = "0",
        score = "0",
        desc_meta = "",
        part = "",
        site = "",
    } = record || {};
    const fullItemRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (!fullItemRef.current) return;
        document.body.style.setProperty(
            "--dynamic_height_full_item",
            `${fullItemRef.current?.clientHeight + 100}px`
        );
    }, [fullItemRef.current]);

    const descriptionWithAdv = useMemo(() => {
        if (!description) return <></>;
        const div: HTMLDivElement = document.createElement("div");
        div.innerHTML = description;

        const childNodes = Array.from(div.childNodes) as HTMLElement[];

        const page: React.JSX.Element | React.JSX.Element[] = []; //div
        let paragraph: React.JSX.Element = <p key={-1} />; //p
        let globalKey = 100;

        childNodes.forEach((element, index) => {
            if (!element) return;
            const name: string = element.nodeName;
            let html: string;
            switch (name) {
                case "#text":
                    html = element.textContent || "";
                    if (element.nodeValue === "\n") return;
                    break;
                default:
                    html = element.outerHTML;
                    break;
            }
            paragraph = <p key={index} dangerouslySetInnerHTML={{ __html: html }}></p>;
            if (Array.isArray(page)) page.push(paragraph);
            paragraph = <p key={index}></p>;
            globalKey++;
        });
        if (Array.isArray(page)) {
            const middle = Math.floor(page.length / 2);
            adsItemsLeft.length &&
                page.splice(
                    middle,
                    0,
                    <div key={globalKey} className="inner_records_adv">
                        <ADV_MINI advItem={adsItemsLeft[0]} className="plety" />
                    </div>
                );
        }
        return page;
    }, [record]);

    const prefix = "https://club.clubok24.ru/content/";
    const src_array: string[] = img?.split(",") || [];
    const mediaElements: React.JSX.Element[] = getMedia({ src_array, prefix });

    return (
        <div ref={fullItemRef} data-id={id} className="item_cell full">
            <div className="title">{title}</div>
            <div className="mediaElement">{mediaElements[0]}</div>
            <div className="description">{descriptionWithAdv}</div>
            <div className="more_block">
                <FULL_RATING {...{ id, score, popularity, setRecord }} />
                <FULL_OKLIKE
                    title={title}
                    id={id}
                    desc_meta={desc_meta}
                    desc_news={description}
                    img={img}
                    link={link}
                    site={site}
                    part={part}
                />
            </div>
            <FULL_SHARE {...{ description, img, img_web, link, title }} />
        </div>
    );
}

export default React.memo(Full, (prevstate: RecordProps, nextstate: RecordProps) => {
    const cond1 = prevstate.record?.id === nextstate.record?.id;
    const cond2 = prevstate.record?.score === nextstate.record?.score;
    const cond3 = prevstate.record?.popularity === nextstate.record?.popularity;

    return cond1 && cond2 && cond3;
});
