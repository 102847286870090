import { FC } from "react";
import HTMLTextContent from "../../utils/functions/HTMLTextContent";

const BASE_URL = "https://super-story.ru/";
const CONTENT_URL = "https://club.clubok24.ru/content/";

interface IShareProps {
    description: string;
    img: string;
    img_web: string;
    title: string;
    link: string;
}

interface IShareItem {
    name: string;
    title: string;
    link: string;
}

const FULL_SHARE: FC<IShareProps> = ({ description, img, img_web, title, link }: IShareProps) => {
    const targetLink = `${BASE_URL}${link}`;
    const textContentLink =
        typeof window !== "undefined"
            ? HTMLTextContent(description)?.substring(0, 200) || "CLUBOK[24]"
            : "CLUBOK[24]";

    const textTitleLink = title?.replaceAll(" ", "");
    let targetImage;

    let firstimg = img?.split(",")[0];
    targetImage = CONTENT_URL + firstimg;
    /*         imagepng = CONTENT_URL + "dd/" + firstimg?.split(".")[0] + ".png"; */
    /* else if (!!img_web) targetImage = img_web.split(",")[0]; */
    console.log(targetImage);
    
    const share_items: IShareItem[] = [
        {
            name: "ok",
            title: "Одноклассники",
            link: `connect.ok.ru/offer?url=${targetLink}&title=${title}&imageUrl=${
                /* imagepng || */ targetImage
            }&description=${textContentLink}`,
        },
        {
            name: "vk",
            title: "Вконтакте",
            link: `vk.com/share.php?url=${targetLink}&title=${title}&image=${targetImage}&description=${textContentLink}&noparse=true`,
        },
        {
            name: "tg",
            title: "Телеграм",
            link: `t.me/share/url?url=${targetLink}&text=${textContentLink}`,
        },
        {
            name: "wh",
            title: "WhatsApp",
            link: `api.whatsapp.com/send/?text=${textContentLink} ${targetLink}`,
        },
        {
            name: "pin",
            title: "Pinterest",
            link: `ru.pinterest.com/pin/create/button/?url=${targetLink}&media=${
                /* imagepng || */ targetImage
            }&description=${textTitleLink}`,
        },
    ];

    const elementsShareItems = share_items.map((el, index) => {
        const { name, title, link } = el;

        return (
            <div
                key={index}
                className={`share_icon ${name}_icon`}
                onClick={() => {
                    window.open(
                        encodeURI(`https://${link}`),
                        "sharer",
                        "status=0,toolbar=0,width=650,height=500"
                    );
                }}
            >
                <div className="share_tooltip">{title}</div>
                <button data-social={name} className={`share_button ${name}`} />
            </div>
        );
    });

    return (
        <div className="share_social_block">
            <span className="title_social">
                <span className="arrow">&dArr;</span>
                Поделиться с друзьями
                <span className="arrow">&dArr;</span>
            </span>
            <div className="social_row_items">{elementsShareItems}</div>
        </div>
    );
};

export default FULL_SHARE;
