import React, { FC, useEffect, useRef } from "react";
import HTMLTextContent from "../../utils/functions/HTMLTextContent";

type PropsFULL_OKLIKE = {
    img: string;
    link: string;
    desc_meta: string;
    desc_news: string;
    site: string;
    id: string | number;
    title: string;
    part: string;
};
const MAIN_SITE = "clubok24.ru";
const BASE_URL = "https://super-story.ru/";
const CONTENT_URL = "https://club.clubok24.ru/content/";

const FULL_OKLIKE: FC<PropsFULL_OKLIKE> = ({
    id,
    title,
    img,
    desc_meta,
    desc_news,
    link,
    site,
    part,
}: PropsFULL_OKLIKE) => {
    const OK = window.OK;
    const ref = useRef(null);
    const el_id = `ok_shareWidget_${id}`;

    async function insertShareWidget() {
        let targetImage;
        let firstimg = img?.split(",")[0];
        targetImage = CONTENT_URL + firstimg;

        /* TODO: используется только основное изображение с форматом webp, переделать на доп (png) */
        /*imagepng = CONTENT_URL + "dd/" + firstimg?.split(".")[0] + ".png"; */

        const did =
            site === MAIN_SITE
                ? `${MAIN_SITE}/category/${part?.split("=")[0]}/${link}`
                : `${BASE_URL}${link}`;
        const st = '{"sz":30,"st":"oval","ck":1,"bgclr":"ED8207","txclr":"FFFFFF"}';

        const target = document.getElementById(el_id) as HTMLDivElement;
        target.innerHTML = "";
        await OK.CONNECT.insertShareWidget(
            el_id,
            did,
            st,
            title,
            HTMLTextContent(desc_news).substring(0, 200),
            targetImage
        );
    }

    useEffect(() => {
        if (!ref.current) return;
        insertShareWidget();
    }, [ref.current]);

    if (!OK) return <></>;
    return <div className="full_share_ok" id={el_id} ref={ref}></div>;
};

export default FULL_OKLIKE;
