import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

declare global {
    interface Window {
        OK?: any;
    }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
