
import { IShortContentItem } from "../../models";
import getMedia from "../../utils/functions/getMedia";

interface ISHortRecordProps {
    record: IShortContentItem;
}

const url = "https://club.clubok24.ru/content/";

export default function Short({ record }: ISHortRecordProps) {
    const { id, link, img, img_web, title, contrast, rgba, view } = record || {};

    const styleVariable = {
        "--rgb_color_aplha": `${rgba}, 0)`,
        "--rgb_color": `${rgba}, 1)`,
    } as React.CSSProperties;

    function onClickShortRecord(e: React.SyntheticEvent) {
        e.preventDefault();
        e.stopPropagation();
        window.open(e.currentTarget.getAttribute("href") || "", "_blank");
        window.location.replace("/");
    }
    const prefix = "https://club.clubok24.ru/content/";
    const src_array: string[] = img?.split(",") || [];
    const mediaElements: React.JSX.Element[] = getMedia({ src_array, prefix });

    return (
        <a
            data-id={id}
            className="item_cell short unusual"
            style={{ backgroundColor: `${rgba})` }}
            href={`/${link}/more/`}
            tabIndex={0}
            target="_blank"
            onClick={onClickShortRecord}
        >
            <span className="mediaElement">
                {mediaElements[0]}
                <span style={styleVariable} className="gradient" />
            </span>
            <span
                style={{
                    backgroundColor: `${rgba})`,
                    color: contrast,
                }}
                className="contentElement"
            >
                <span className="title">{title}</span>
                <span className="read_more">
                    Читать <span></span>
                </span>
            </span>
        </a>
    );
}
