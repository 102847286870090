import { ReactNode, useEffect, useRef } from "react";

const CLASSNAME_MEDIA_LAZY = "lazy_media_clubok24";
const mutationContainerId = "layout_mutation_observer";

interface IObserversProps {
    children: ReactNode;
    className?: string;
}

function observeLazyMedia() {
    const lazyloadMedia = document.querySelectorAll<HTMLMediaElement>(`.${CLASSNAME_MEDIA_LAZY}`);
    const callback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const loadedMediaData = () => {
                    targetMedia.classList.remove(CLASSNAME_MEDIA_LAZY);
                    console.log("loaded", targetMedia.src);

                    observer.unobserve(targetMedia);
                    targetMedia.removeEventListener("loadeddata", loadedMediaData);
                    if (targetMedia.tagName === "VIDEO") targetMedia.play();
                };

                const targetMedia = entry.target as HTMLMediaElement;
                targetMedia.src = targetMedia.dataset.src || "";
                targetMedia.addEventListener("loadeddata", loadedMediaData);
            }
        });
    };
    const options: IntersectionObserverInit = {
        root: null,
        rootMargin: "5px",
        threshold: 0.1,
    };
    const observer = new IntersectionObserver(callback, options);
    lazyloadMedia.forEach((target) => observer.observe(target));
}

function observeMutationContainer() {
    const container = document.getElementById(mutationContainerId);
    if (!container) return;

    const config = {
        attributes: true,
        childList: true,
        subtree: true,
    };

    const callback: MutationCallback = function (mutationsList) {
        for (let mutation of mutationsList) {
            for (let node of mutation.addedNodes as any) {
                if (!(node instanceof HTMLElement)) continue;
                const selectorMedia = document.querySelectorAll(`.${CLASSNAME_MEDIA_LAZY}`);

                if (selectorMedia.length) observeLazyMedia();
            }
        }
    };

    const observer = new MutationObserver(callback);
    observer.observe(container, config);
}
let isObserve = false;
export default function Observers({ children, className }: IObserversProps) {
    useEffect(() => {
        const iterateObserve = () => {
            observeLazyMedia();
            observeMutationContainer();
            isObserve = true;
        };
        if (isObserve) return;
        let timer = setInterval(iterateObserve, 3000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div id={mutationContainerId} className={className}>
            {children}
        </div>
    );
}
