import React, { useEffect, useState } from "react";
import { ITrashItem, IMiniAdv } from "../models";
import TrashRecord from "../components/items/TrashRecord";
import UnusualRecord from "../components/items/UnusualRecord";
import { randomNumber } from "../utils/functions/numbers";
import { useParams } from "react-router-dom";
import ADV_mini from "../components/items/ADV_MINI";
import Loading from "../components/Loading";
import { useFeedState } from "../context/FeedProvider";
import InfiniteFeed from "./InfiniteFeed";
import { useResize } from "../hooks/useResize";

const advElements = (items: IMiniAdv[]) =>
    items?.map((element, index) => {
        if (index < 4 || index % 4 !== 0)
            return (
                <ADV_mini
                    className="plety"
                    randTime
                    moreButton="Читать ещё.."
                    key={index}
                    advItem={element}
                />
            );
        else return <ADV_mini className="lone" pseudoInfoButton key={index} advItem={element} />;
    });

export default function Wrapper() {
    const { width = 781 } = useResize();
    const mobileSize = width <= 780;

    const { trashItems = [], adsItemsRight = [], adsItemsLeft = [], getAds, getTrash } = useFeedState() || {};
    const { innerlink } = useParams();
    const [buildedRows, setBuildedRows] = useState<React.ReactNode[]>([]);

    const [fetchingLimitAdv, setFetchingLimitAdv] = useState<boolean | number>(false);

    const buildGrid = (array: ITrashItem[]): void => {
        const collectionRows: JSX.Element[] = [];
        let row: JSX.Element[] = [];
        let randCountChild: number = randomNumber(2, 3);
        let randGrowChild: number = randomNumber(1, 2);

        function separateRow(key: string, countChild: number, growChild: number) {
            collectionRows.push(
                <div data-grow_child={growChild} data-count_child={countChild} key={key} className="row">
                    {row}
                </div>
            );
            row = [];
            randCountChild = randomNumber(2, 3);
            randGrowChild = randomNumber(1, 2);
        }
        function buildRow(trash: ITrashItem, index: number) {
            const key = `${trash.id}_${index}_trash`;
            row.push(<TrashRecord key={key} trash={trash} />);
            if (row.length >= randCountChild) separateRow(key, randCountChild, randGrowChild);
        }

        collectionRows.push(
            <UnusualRecord random key={`${collectionRows.length}_${buildedRows.length}_unusual`} />
        );
        array.map(buildRow);

        setBuildedRows((prevstate) => [...prevstate, collectionRows]);
    };

    const onEndScrollFeed = (pos: string) => {
        switch (pos) {
            case "lcol":
                if (getAds) getAds({ limit: 6, method: "increment", name: "adsItemsLeft" });
                break;
            case "rcol":
                if (getAds) getAds({ limit: 6, method: "increment", name: "adsItemsRight" });
                break;
            case "ccol":
            default:
                if (getTrash) getTrash({ limit: 9, method: "increment", name: "trashItems" });
                break;
        }
    };

    useEffect(() => {
        if (trashItems.length) buildGrid(trashItems);
    }, [trashItems.length]);

    return (
        <div className="column_container">
            {!mobileSize && (
                <InfiniteFeed
                    offsetBottom={300}
                    onEndScroll={() => onEndScrollFeed("lcol")}
                    className="left_column"
                >
                    {advElements(adsItemsLeft)}
                    {fetchingLimitAdv !== false && <Loading onClick={() => setFetchingLimitAdv(4)} />}
                </InfiniteFeed>
            )}
            <InfiniteFeed
                offsetBottom={300}
                onEndScroll={() => onEndScrollFeed("ccol")}
                className={`column ${innerlink ? "record_column" : ""}`}
            >
                <UnusualRecord />
                {buildedRows}
                <Loading onClick={() => onEndScrollFeed("ccol")} />
            </InfiniteFeed>
            {!mobileSize && (
                <InfiniteFeed
                    offsetBottom={300}
                    onEndScroll={() => onEndScrollFeed("rcol")}
                    className="right_column"
                >
                    <div className="adv_news_container">
                        <h2 className="title">Последние новости</h2>
                    </div>

                    {advElements(adsItemsRight)}
                    {fetchingLimitAdv !== false && <Loading onClick={() => setFetchingLimitAdv(4)} />}
                </InfiniteFeed>
            )}
        </div>
    );
}
