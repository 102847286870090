function randomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function declOfNum(n: number, text_forms: string[]) {
    n = Math.abs(n) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }

    if (n1 === 1) {
        return text_forms[0];
    }
    return text_forms[2];
}
/* function averageNumberArray(array: number[], th: number = 1) {
    if (!array) return 0;
    const lengthDetailRating = array?.length || 1;
    const sumWithInitial = array.reduce(
        (accumulator, currentValue) => accumulator + parseInt(currentValue?.score, 10) || 1,
        0
    );
    return parseFloat((sumWithInitial / lengthDetailRating).toFixed(th), 10);
} */

export { randomNumber, declOfNum /* averageNumberArray */ };
