import React, { useEffect } from "react";
import getMedia from "../../utils/functions/getMedia";
import Slider from "../../wrappers/Slider";
import { randomNumber } from "../../utils/functions/numbers";
import onClickLink from "../../utils/helpers/onClickLink";
import { IMiniAdvProps } from "../../models";

const prefix = "https://club.clubok24.ru/content/";

function ADV_MINI({ className, pseudoInfoButton, randTime, moreButton, advItem }: IMiniAdvProps) {
    const { min = 1, max = 59, unit = "мин." } = typeof randTime === "object" ? randTime : {};
    const { id = 0, link = "", footnote = "", img = "", title = "" } = advItem || {};

    const src_array: string[] = img?.split(",") || [];

    const mediaElements: React.JSX.Element[] = getMedia({ src_array, prefix });
    return (
        <a
            href={link}
            data-id={id}
            data-path="adv"
            className={`adv_mini ${className}`}
            onClick={onClickLink}
            onMouseDown={onClickLink}
        >
            <div className="slider_container">
                {pseudoInfoButton && <div className="pseudo_info_button" />}
                <Slider randomizeTime={1000} interval={9000}>
                    {mediaElements}
                </Slider>
            </div>
            <div className="text_content">
                <div title={title} className="title_wrapper">
                    {title.trim()}
                </div>
                {footnote && (
                    <div title={footnote} className="footnote">
                        {footnote.trim()}
                    </div>
                )}
                <div className="about_block">
                    {moreButton && <div className="more_button">{moreButton}</div>}
                    {randTime && (
                        <div className="creation_time">
                            {randomNumber(min, max)} {unit} назад
                        </div>
                    )}
                </div>
            </div>
        </a>
    );
}
export default React.memo(ADV_MINI, (prev, next) => {
    if (!prev.advItem || !next.advItem) return false;
    if (prev?.advItem.id !== next?.advItem.id) return false;
    else return true;
});
