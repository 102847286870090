const video_formats = ["mp4", "ogg", "avi", "mov", "vob", "webm", "mkv", "flv", "mts", "3gp", "insv", "m2tc"];

export function isVideo(src: string) {
    const arraySrc = src.split(".");
    const extension = arraySrc[arraySrc.length - 1];

    if (video_formats.includes(extension)) return true;
    else return false;
}

export function isGif(src: string) {
    const arraySrc = src.split(".");
    const extension = arraySrc[arraySrc.length - 1];

    if (extension === "gif") return true;
    else return false;
}
