export const throttle = (func: () => void, ms: number) => {
    let locked = false;

    return function (this: any) {
        if (locked) return;
        const context = this;
        const args = arguments as any;
        locked = true;

        setTimeout(() => {
            func.apply(context, args);
            locked = false;
        }, ms);
    };
};

export function wait(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
}
