import { useEffect, useState } from "react";

const useResize = () => {
    const [display, setDisplay] = useState(() => {
        if (typeof window === "undefined")
            return {
                width: 0,
                height: 0,
            };
        else
            return {
                width: window.innerWidth,
                height: window.innerHeight,
            };
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            const handleResize = (event: any) => {
                setDisplay((prevstate) => ({
                    ...prevstate,
                    height: event.target.innerHeight,
                    width: event.target.innerWidth,
                }));
            };
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    return { ...display };
};

export { useResize };
