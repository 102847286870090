import React from "react";
import { isVideo } from "./testImage";

type MouseEvents = (e: React.MouseEvent<HTMLImageElement>) => void;
type ImageParams = {
    src: string;
    alt: string;
    className: string;
    "data-src": string;
    "data-src_original": string;
    key?: number;
    onClick?: MouseEvents;
};

const className = "media_news";
const BASE_URL = "https://club.clubok24.ru/content/";
const url_orig = "https://club.clubok24.ru/content/original/";
const CLASSNAME_MEDIA_LAZY = "lazy_media_clubok24";

const onError = (e: React.SyntheticEvent<HTMLVideoElement | HTMLImageElement, Event>) => {
    const src = e.currentTarget.dataset.src || "***",
        src_original = e.currentTarget.dataset.src_original || "***",
        errorImg = `${BASE_URL}error.png`;
    let targetSrc = e.currentTarget.src;

    if (!~e.currentTarget.src.indexOf(url_orig)) targetSrc = targetSrc.replace(src, src_original);
    else targetSrc = errorImg;

    if ("poster" in e.currentTarget) e.currentTarget.poster = targetSrc;
    else e.currentTarget.src = targetSrc;
};

const check_empty_array = (arr: Array<string>) => arr.filter((val) => val.trim() !== "").length > 0;

const VIDEO_JSX = (src: string, src_converted: string, className: string, key: number) => (
    <video
        key={key}
        className={`${className} ${CLASSNAME_MEDIA_LAZY}`}
        muted
        loop
        preload="auto"
        data-src={src_converted}
        data-src_original={src}
        onError={onError}
    />
);

const IMAGE_JSX = (imageParams: ImageParams) => <img {...imageParams} onError={onError} />;

interface IGetMedia {
    src_array: string[];
    prefix?: string | boolean;
    onClickImage?: MouseEvents;
}

export default function getMedia({ src_array, prefix = false, onClickImage }: IGetMedia) {
    const mediaElements: React.JSX.Element[] = [];
    let key = 0;

    const pushElements = (src: string) => {
        key++;
        let nameArr = src.split("!");
        let src_converted = nameArr[0] + "!" + nameArr[1].split(".")[0];

        if (isVideo(src)) mediaElements.push(VIDEO_JSX(src, src_converted + ".webm", className, key));
        else {
            const allImageParams = {
                key,
                alt: "",
                "data-src": src_converted + ".webp",
                "data-src_original": ~src.indexOf(".webp") ? src : src.replace(BASE_URL, url_orig),
                onClick: onClickImage,
            };

            const imageParams: ImageParams = {
                src: src_converted + ".webp",
                className,
                ...allImageParams,
            };

            mediaElements.push(IMAGE_JSX(imageParams));
        }
    };

    check_empty_array(src_array) && src_array.map((src) => pushElements(prefix + src));
    return mediaElements;
}
